import React from 'react'
import ContactHeader from '../Components/Contactus/ContactHeader'
import ContactForm from '../Components/Contactus/ContactForm'
import Footer from '../Components/Footer/Footer'
import HomeContact from '../Components/Home/HomeContact'
import contectimg from '../Assets/Contactform.jsx.png';

const ContactUs = () => {
    const color = 'bg-[#1F4266]';
    return (
        <>
            <ContactHeader />
            <HomeContact image={contectimg} color={color} />
            {/* <ContactForm /> */}
            <Footer />
        </>
    )
}

export default ContactUs
