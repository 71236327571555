import React from 'react'
import AboutHead from '../Components/AboutUs/AboutHead'
import AboutDetail from '../Components/AboutUs/AboutDetail'
import AboutContact from '../Components/AboutUs/AboutContact'
import Footer from '../Components/Footer/Footer'
import HomeContact from '../Components/Home/HomeContact'
import contectimg from '../Assets/Contactform.jsx.png';

const AboutUs = () => {
    const color = 'bg-[#1F4266]';
    return (
        <>
            <AboutHead />
            <AboutDetail />
            <HomeContact image={contectimg} color={color} />
            {/* <AboutContact /> */}
            <Footer />
        </>
    )
}

export default AboutUs
