import React from "react";
import "../Styles/Styles.css";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

// import { IoLogoGithub } from "react-icons/io5";
// import { FaTwitter } from "react-icons/fa";
// import { SiDiscord } from "react-icons/si";
import { Route, Routes, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row p-[40px] gap-[30px] items-center justify-center">
        <div className="flex flex-col gap-[20px] lg:h-[185px] justify-between">
          <img
            src={require("../../Assets/footerlogo.png")}
            className="w-[300px] xl:w-[396px] h-[112px]"
            alt=""
          />
          <div className="divider"></div>
        </div>
        <div className="flex flex-col items-center gap-[30px]">
          <div className="flex flex-col">
            <div className="text-[#3C3C3C] text-[32px] leading-[48px] fFamily font-[600]">
              Quick Links
            </div>
            <Link
              to="/"
              className="linkColor text-[19px] fFamily leading-[28px] mb-[5px]  "
            >
              Home
            </Link>
            <Link
              to="/contactus"
              className="linkColor text-[19px] fFamily leading-[28px] mb-[5px]  "
            >
              Contact us
            </Link>
            {/* <Link
              to="/aboutus"
              className="linkColor text-[19px] fFamily leading-[28px] mb-[5px]  "
            >
              About us
            </Link> */}
          </div>
          <div className="fFamily text-[#292929] text-[19px] text-center leading-[25px]">
            Copyright @ 2024 All rights are reserved{" "}
          </div>
        </div>
        <div className="flex flex-col gap-[20px] lg:h-[185px] justify-between">
          <div className="flex flex-col px-[25px]">
            <div className="text-[#3C3C3C] text-[32px] leading-[48px] fFamily font-[600]">
              Follow us:
            </div>
            <div className="flex items-center gap-[20px] mt-[10px]">
              <FaInstagram className="text-[32px] text-[#6AD981]" />
              <FaLinkedin className="text-[32px] text-[#6AD981]" />

              {/* <IoLogoGithub className='text-[32px] text-[#6AD981]' />
                            <SiDiscord className='text-[32px] text-[#6AD981]' />
                            <FaTwitter className='text-[32px] text-[#6AD981]' /> */}
            </div>
          </div>
          <div className="divider"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
