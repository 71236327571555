import './App.css';
import AdminSignIn from './Components/Admin/AdminSignIn';
import AboutUs from './Pages/AboutUs';
import AdminHome from './Pages/AdminHome';
import Brands from './Pages/Brands';
import ContactUs from './Pages/ContactUs';
import Home from './Pages/Home';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
} from "react-router-dom";
import Productblog from './Pages/Productblog';
import { useState } from 'react';
import NotFound from './Components/Notfound/NotFound';

function App() {
  const PrivateRoutes = () => {
    const [token, setAdminToken] = useState(localStorage?.getItem("adminId"));


    return token !== null ? <Outlet /> : <Navigate to="/admin" />;
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/admin" element={<AdminSignIn />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
        <Route path="/notfound" element={<NotFound />} />

        <Route path="/productblog" element={<Productblog />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/adminhome" element={<AdminHome />} />
        </Route>

      </Routes>

    </>
  );
}

export default App;
