import React from 'react'
import BrandsHead from '../Components/Brands/BrandsHead'
import BrandFaq from '../Components/Brands/BrandFaq'
import BrandContact from '../Components/Brands/BrandContact'
import Footer from '../Components/Footer/Footer'
import HomeContact from '../Components/Home/HomeContact'
import contectimg from '../Assets/brandscontact.png'

const Brands = () => {
    const color = 'bg-[#6AD981]';
    return (
        <>
            <BrandsHead />
            <BrandFaq />
            {/* <BrandContact /> */}
            <HomeContact image={contectimg} color={color} />
            <Footer />
        </>
    )
}

export default Brands
