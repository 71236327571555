import React, { useState } from 'react'
import '../Styles/Styles.css'
import NavbarBrandCuts from '../Navbar/Navbar'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import BASEURL from '../../Utlis/serverinstance'

const BrandFaq = () => {
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const location = useLocation();
    const ProductItem = location.state?.SetBrand;

    const toggleAccordion = (accordionId) => {
        setExpandedAccordion(expandedAccordion === accordionId ? null : accordionId);
    };

    return (
        <>
            <div className='w-full py-[20px] px-[15px]'>
                <div className='flex flex-col items-center'>
                    <img src={`${BASEURL}/img/${ProductItem?.PermanentHeadingImage}`} className='w-[276px]' alt="" />
                    <div className='text-[#484848] text-[16px] leading-[24px] fFamily text-center'>
                        All of our products are sourced either directly from the brand, through <br /> agents or from authorised stockists. We guarantee that all products <br /> are 100% genuine, and free to distribute or sell within the EU.
                    </div>
                </div>
                <div className='w-[full] ml-auto mt-[70px] mr-auto max-w-[870px] flex flex-col '>
                    <div className='text-[26px] lg:text-[32px] text-[#F0B118] leading-[32px] text-center font-[600]'>Frequently asked questions</div>
                    <div className='flex accorSection mt-[10px] w-full flex-col gap-[15px] pt-[20px]'>
                        <Accordion expanded={expandedAccordion === 1} onChange={() => toggleAccordion(1)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 1 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>What are the {ProductItem?.PermanentHeadingTitle} prices?</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {ProductItem?.PermanentHeadingTitle} wholesale prices are hidden. In order to see our offers and {ProductItem?.PermanentHeadingTitle} prices you'd need to subscribe to our newsletter.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedAccordion === 2} onChange={() => toggleAccordion(2)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 2 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>Is your stock / {ProductItem?.PermanentHeadingTitle} stock authentic?</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes, all {ProductItem?.PermanentHeadingTitle} wholesale items listed by us are 100% authentic and come directly from the brand. We are overseen by the EU trading agency and UK National Trading Standards and have a great reputation in the designer and wholesale industry as a market leader.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedAccordion === 3} onChange={() => toggleAccordion(3)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 3 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>Can I use {ProductItem?.PermanentHeadingTitle} for resale in retails / ecommerce?</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {ProductItem?.PermanentHeadingTitle} items purchased from That Designer Wholesale can be safely resold within the European Economic Area (EEA). Stock will be sent from an EU country and will be free of any tariffs.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedAccordion === 4} onChange={() => toggleAccordion(4)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 4 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>Are there any requirements to buy {ProductItem?.PermanentHeadingTitle} items?</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                We are a B2B company and therefore only sell to businesses. You usually need to be registered for VAT to place an order, but exceptions may be made. Write to us if you do not have a VAT number.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedAccordion === 5} onChange={() => toggleAccordion(5)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 5 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>Do you sell by KG or item?</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                All our stock is sold by item. You will rarely find that we send mixed lot offers, these would be exceptional and will only be done through our newsletter..
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedAccordion === 6} onChange={() => toggleAccordion(6)}>
                            <AccordionSummary
                                expandIcon={expandedAccordion === 6 ? <FaMinus className='text-[#F0B118]' /> : <FaPlus className='text-[#F0B118]' />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='text-[#262626] fFamily text-[16px] font-bold leading-[24px]'>Where can I find {ProductItem?.PermanentHeadingTitle} Offers</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                Use the form below to request {ProductItem?.PermanentHeadingTitle} stock information.
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandFaq
