import React from 'react'
import '../Styles/Styles.css'
import NavbarBrandCuts from '../Navbar/Navbar'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';



const BrandsHead = () => {
    const location = useLocation();
    const ProductItem = location.state?.SetBrand;

    console.log(ProductItem, "ProductItem")

    return (
        <>
            <div className='headbrand relative'>
                <img src={require('../../Assets/brandHead.png')} alt="" className='w-full h-full relative z-1' />
                <div className='absolute z-2 top-0 w-full h-full'>
                    <NavbarBrandCuts />
                    <div className='flex flex-col w-[full] items-center px-[15px] mt-[60px] md:mt-[100px]'>
                        <div className='fFamily text-white leading-[20px] md:leading-[32px] text-[16px] md:text-[20px] font-[500] '>WHOLESALE SUPPLIER & DISTRIBUTOR​</div>
                        <div className='fFamily text-white text-[35px] text-center md:text-[48px]  font-[700] leading-[40px] md:leading-[56px]'>{ProductItem?.PermanentHeadingTitle}</div>
                        <div className='fFamily text-white leading-[20px] md:leading-[32px] text-[16px] md:text-[20px] font-[400] text-center '>
                            We stock all range of {ProductItem?.PermanentHeadingTitle} wholesale clothing for both men <br /> and women including but not limited to t-shirts, polos, hoodies, <br />  tracksuits, shorts, outwear & more.
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full py-[40px] md:py-[60px] px-[15px]'>
                <div className='w-[full] ml-auto mr-auto max-w-[1200px] flex flex-col '>
                    <div className='text-[26px] md:text-[32px] text-[#1B1B1B] leading-[32px] text-center font-[600]'>{ProductItem?.PermanentHeadingTitle} offers:</div>
                    <div className='flex accorSection w-full flex-col gap-[15px] pt-[20px]'>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <div className='flex items-center gap-[10px]'>
                                    <div className='w-[30px] h-[30px] bg-[#6BD9B3] rounded-full'></div>
                                    <div className='text-[#181818] fFamily text-[16px] font-bold leading-[24px]'>Special Offers</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <div className='flex items-center gap-[10px]'>
                                    <div className='w-[30px] h-[30px] bg-[#EFA41C] rounded-full'></div>
                                    <div className='text-[#181818] fFamily text-[16px] font-bold leading-[24px]'>NOS Stock</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <div className='flex items-center gap-[10px]'>
                                    <div className='w-[30px] h-[30px] bg-[#EFA41C] rounded-full'></div>
                                    <div className='text-[#181818] fFamily text-[16px]  font-bold leading-[24px]'>Wholesale Preorders</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='text-[14px] fFamily text-[#181818] p-[20px] leading-[20px]'>
                        Emporio Armani is currently not available for pre-orders, visit our <span className='decorSolid'>Pre-Order page for more information.</span>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandsHead
