import React from 'react'
import '../Styles/Styles.css'
import NavbarBrandCuts from '../Navbar/Navbar'

const ContactHeader = () => {
    return (
        <>
            <div className='Contactbrand relative mb-[-200px] lg:mb-[-145px]'>
                <img src={require('../../Assets/contactHead.png')} alt="" className='w-full h-full relative z-1' />
                <div className='absolute z-2 top-0 w-full h-full'>
                    <NavbarBrandCuts />
                    <div className='flex flex-col w-[full] items-center px-[15px] mt-[60px] md:mt-[100px]'>
                        <div className='fFamily text-white text-[35px] text-center md:text-[41px] font-[700] leading-[52px]'>Let's talk with us</div>
                        <div className='fFamily text-white leading-[20px] md:leading-[32px] text-[16px] md:text-[20px] font-[400] text-center max-w-[651px]'>
                            Get in touch with one of our sale representatives. For general enquiries, we aim to respond within 1-3 working days.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactHeader
