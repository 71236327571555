import React from "react";
import "../Styles/Styles.css";
import NavbarBrandCuts from "../Navbar/Navbar";
import UpcomingBrands from "./UpcomingBrands";

const HomeHead = () => {
  return (
    <>
      <div className="mainPhoto relative">
        <img
          src={require("../../Assets/HomeHead.png")}
          alt=""
          className="w-full h-full relative z-1"
        />
        <div className="absolute z-2 top-0 w-full h-full">
          <NavbarBrandCuts />
          <div className="flex flex-col w-[full] items-center px-[15px] mt-[60px] md:mt-[100px]">
            <div className="fFamily text-white text-[35px] text-center md:text-[48px] font-[700] ">
              Welcome to Desimo Group AB!
            </div>
            <div className="mt-[5px] fFamily text-white text-[16px] md:text-[20px] max-w-[680px] text-center leading-[20px] md:leading-[32px]">
              Desimo Group AB is a wholesaler offering a unique range of
              high-quality products from our most renowned brands. With many
              years in the industry, we understand the importance of providing a
              current and constantly renewed assortment. At the same time, we
              continuously work with our suppliers on staple goods that remain
              ever relevant. We have a strong portfolio of well-known brands,
              but we always listen to our customers and meet their needs.
            </div>
            <div className=" mt-[40px] fFamily text-white text-[16px] md:text-[20px] max-w-[680px] text-center leading-[20px] md:leading-[32px]">
              Currently, we can offer our customers and suppliers a third-party
              logistics solution, with integrated order flows and more.
            </div>
          </div>
        </div>
      </div>
      {/* <UpcomingBrands /> */}
    </>
  );
};

export default HomeHead;
