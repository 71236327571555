import React from 'react'
import AdminHomeHead from '../Components/AdminHome/AdminHomeHead.jsx/AdminHomeHead'
import Footer from '../Components/Footer/Footer'
import AdminHomeBrands from '../Components/AdminHome/AdminHomeBrands/AdminHomeBrands'

const AdminHome = () => {
    return (
        <>

            <AdminHomeBrands />
            <AdminHomeHead />
            <Footer />
        </>
    )
}

export default AdminHome
