import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AdminSignIn.module.css'
import BASEURL from '../../Utlis/serverinstance';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const AdminSignIn = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const navigate = useNavigate();
    const [user, setUser] = useState(null);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if any field is empty
        if (formData.email.trim() === '' || formData.password.trim() === '') {
            // Show SweetAlert for empty fields
            Swal.fire({
                icon: 'warning',
                title: 'Empty Fields',
                text: 'Please enter credentials in both fields',
                confirmButtonColor: "#008cba"
            });
            return;  // Stop further execution
        }

        // Both fields are filled, proceed with the API call
        axios
            .post(`${BASEURL}/adminlogin/loginAdmin`, formData)
            .then((response) => {
                // const userId = response?.data?.token;
                // const decodedToken = jwtDecode(response?.data?.token);
                localStorage.setItem("adminId", response.data.user._id);
                navigate('/adminhome');
            })
            .catch((error) => {
                // Handle errors
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message || 'Something went wrong!. Please enter correct credentials',
                });
            });


    };
    return (
        <>
            <div className={styles.signouter}>
                <div className={styles.mainDiv}>
                    <div className={styles.signtext}>Admin login</div>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={styles.emailInput}
                        placeholder="Email"
                    />
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className={styles.emailInput}
                        placeholder="password"
                    />
                    <Link onClick={handleSubmit} className={styles.saveBtn}>Login</Link>
                    {/* <div className={styles.alreadyLogin}>Not a member? <Link to="/" className={styles.loginText}>Signup now</Link> </div> */}
                </div>
            </div>
        </>
    )
}

export default AdminSignIn
