import React, { useState } from 'react';
import { MdDeleteForever } from "react-icons/md";
import styles from './PermanentLinks.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BASEURL from '../../../Utlis/serverinstance'
import axios from 'axios';
import Swal from 'sweetalert2'


const DeleteBrand = ({ ItemsId, onDeleteSuccess }) => {
    const deleteItemId = ItemsId
    const [modalShow, setModalShow] = useState(false);


    function deleteQuestion() {

        axios.delete(`${BASEURL}/permanentNews/DailyHeadingdelete/${deleteItemId}`)
            .then(response => {
                Swal.fire('Success', 'Brand deleted succesfully', 'success');
                // props.onHide();
                onDeleteSuccess();
                setModalShow(false)
            })
            .catch(error => {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message || 'Something went wrong!',
                });
            });
    }
    return (
        <>

            <MdDeleteForever className={styles.editIcon1} onClick={() => setModalShow(true)} />


            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete Brand
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to delete this Brand?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={deleteQuestion} className={styles.cancelBtn}>Delete</Button>
                    <Button onClick={() => setModalShow(false)} className={styles.cancelBtn}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default DeleteBrand
