import React from "react";
import HomeHead from "../Components/Home/Homehead";
import Footer from "../Components/Footer/Footer";
import HomeContact from "../Components/Home/HomeContact";
// import HomeAbout from '../Components/Home/HomeAbout'
// import NewsUpdates from '../Components/Home/NewsUpdates'
import contectimg from "../Assets/homeContact.png";

const Home = () => {
  const color = "bg-[#F0B118]";
  return (
    <div>
      <HomeHead />
      {/* <NewsUpdates />
            <HomeAbout /> */}
      <HomeContact image={contectimg} color={color} />
      <Footer />
    </div>
  );
};

export default Home;
