import React, { useState, useEffect } from "react";
import "../../Styles/Styles.css";
import bran1 from "../../../Assets/brand1.png";
import bran2 from "../../../Assets/brand2.png";
import bran3 from "../../../Assets/brand3.png";
import bran4 from "../../../Assets/brand4.png";
import { FaArrowRightLong } from "react-icons/fa6";
import AddUpdate from "./AddUpdate";
import BASEURL from "../../../Utlis/serverinstance";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteNews from "./DeleteNews";
import { Link, useLocation } from "react-router-dom";
import NavbarBrandCuts from "../../Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const AdminHomeHead = () => {
  const [mainHeading, setMainHeading] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASEURL}/dailyNews/DailyHeadings`);
      setMainHeading(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteSuccess = () => {
    fetchData();
  };

  return (
    <>
      <div className="w-full pt-[50px] pb-[40px] relative">
        <img
          src={require("../../../Assets/productsHome.png")}
          className="absolute top-0 z-1 h-full w-full"
          alt=""
        />
        <div className="flex flex-col  ml-auto mr-auto w-full max-w-[1100px] 2xl:max-w-[1200px] relative z-2 px-[15px]">
          <div className="flex justify-end w-full">
            <AddUpdate onDeleteSuccess={handleDeleteSuccess} />
          </div>
          <div className="News & Updates fFamily text-center text-[26px] md:text-[32px] text-white font-[600] mt-[10px]">
            News & Updates
          </div>
          <div className="flex md:flex-row flex-col flex-wrap  md:justify-between w-full">
            {mainHeading?.map((item) => (
              <div className="flex flex-col mt-[30px] w-full max-w-[400px] md:max-w-none md:w-[48.5%] ">
                <div className="w-full p-[10px] flex justify-end">
                  <DeleteNews
                    ItemsId={item._id}
                    onDeleteSuccess={handleDeleteSuccess}
                  />
                </div>
                {item.DailyHeadingImage && (
                  <img
                    src={`${BASEURL}/img/${item?.DailyHeadingImage}`}
                    className="w-full sm:h-[350px] h-[250px] max-h-[350px]"
                    alt=""
                  />
                )}
                <div className="flex w-full gap[20px] mt-[8px] justify-between items-center">
                  <div className="flex flex-col w-full max-w-[390px]">
                    <div className="txt-[20px] md:text-[24px] text-white leading-[25x] md:leading-[38px] font-[600]">
                      {item?.DailyHeadingURL}
                    </div>
                    <div className="text-[16px] md:text-[19px] text-white leading-[20px] md:leading-[28px]">
                      {item?.DailyHeadingTitle}
                    </div>
                  </div>
                  <Link to={`/productblog`} state={{ productDisplayed: item }}>
                    <FaArrowRightLong className="text-[20px] md:text-[24px] text-white" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHomeHead;
