import React, { useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { MdPhoneInTalk } from "react-icons/md";
import "../Styles/Styles.css";
import { MdOutlineEmail } from "react-icons/md";
import BASEURL from "../../Utlis/serverinstance";
import axios from "axios";
import Swal from "sweetalert2";

const HomeContact = ({ image, color }) => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlefirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handlelastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlenumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendEmail = async () => {
    if (!email || !firstName || !lastName || !number) {
      Swal.fire({
        icon: "error",
        text: "Please fill all mendatory fields",
      });
      return;
    }
    const emailData = {
      email: email,
      message: message,
      firstName: firstName,
      lastName: lastName,
      number: number,
    };
    console.log(emailData);
    try {
      const response = await axios.post(
        `${BASEURL}/email/send-email`,
        emailData
      );

      if (response.data.success) {
        // Display success message with appointment details
        Swal.fire({
          icon: "success",
          title: "Email sent",
        });

        setEmail("");
        setMessage("");
        setFirstName("");
        setLastName("");
        setNumber("");
      } else {
        console.error("Failed to send email.");
        // Handle failure, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  return (
    <>
      <div className="homeContact">
        <img src={image} className="w-full h-full absolute z-1 top-0" alt="" />
        <div className="flex justify-between lg:flex-row flex-col lg:items-center w-full gap-[20px] max-w-[1200px] relative z-2">
          <div className="flex flex-col">
            <div className="fFamily text-white text-[30px] lg:text-[41px] leading-[52px] font-bold">
              Contact sales
            </div>
            <div className="mt-[15px] text-[15px] lg:text-[18px] leading-[20px] lg:leading-[29px] text-white fFamily w-full lg:max-w-[410px]">
              Questions, comments, or suggestions? Simply fill in the form and
              we’ll be in touch shortly.
            </div>
            <div className="flex gap-[10px] mt-[15px] lg:mt-[20px] text-[20px]">
              <IoLocationOutline className="text-[25px] lg:mt-[5px] text-white" />
              <div className=" text-white     padding: 189px 15px 30px; font-bold w-full lg:max-w-[300px]">
                HYLLIE VATTENPARKSGATA 11 A, 215 32, Malmö, Skåne
              </div>
            </div>

            <div className="flex gap-[10px] mt-[15px] lg:mt-[20px] text-[20px]">
              <IoLocationOutline className="text-[25px] lg:mt-[5px] text-white" />
              <div className=" text-white     padding: 189px 15px 30px; font-bold w-full lg:max-w-[300px]">
                Delivery address: Desimo Group AB Bolshedens Industriväg 38
                42750 billdal
              </div>
            </div>
            <div className="flex gap-[10px] mt-[10px] text-[20px]">
              <MdPhoneInTalk className="text-[25px] mt-[5px] text-white" />
              <div className=" text-white     padding: 189px 15px 30px; font-bold w-full max-w-[300px]">
                0103300715
              </div>
            </div>
            <div className="flex gap-[10px] mt-[10px] text-[20px]">
              <MdOutlineEmail className="text-[25px] mt-[5px] text-white" />
              <div className=" text-white     padding: 189px 15px 30px; font-bold w-full max-w-[300px]">
                Helpdesk@desimogroup.com
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-auto lg:mx-0 inputSection px-[10px] py-[20px] md:p-[40px]">
            <div className="flex items-center justify-between w-full">
              <input
                type="text"
                value={firstName}
                onChange={handlefirstNameChange}
                className="johninput"
                placeholder="First Name*"
              />
              <input
                type="text"
                value={lastName}
                onChange={handlelastNameChange}
                className="johninput"
                placeholder="Last Name*"
              />
            </div>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="emailInput"
              placeholder="Email*"
            />
            <input
              type="tel"
              value={number}
              onChange={handlenumberChange}
              className="emailInput"
              placeholder="Phone Number*"
            />
            <textarea
              value={message}
              onChange={handleMessageChange}
              placeholder="Your message..."
              className="textAreaMessage"
            ></textarea>
            <button onClick={sendEmail} className={`sendBtn ${color}`}>
              Send Message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeContact;
