import React from 'react'
import { HiOutlineEmojiSad } from "react-icons/hi";

const NotFound = () => {
    return (
        <>
            <div className='flex justify-center items-center flex-col w-full min-h-[100vh]'>
                <HiOutlineEmojiSad className='text-[200px] sm:text-[250px] text-[#777777]' />
                <div className='text-[50px] sm:text-[60px] text-[#777777] '>404</div>
                <div className='text-[18px] sm:text-[22px] text-[#777777] '>Page not found</div>
                <div className='text-[15px] sm:text-[18px] mt-[30px] font-[500] text-[#777777]'>The page you are looking for doesn't exist.</div>
                <div className='text-[15px] sm:text-[18px]   font-[500] text-[#777777]'>Go back to choose new direction.</div>
            </div>
        </>
    )
}

export default NotFound
