import React from 'react'
import BlogHead from '../Components/ProductBlog/BlogHead'

const Productblog = () => {
    return (
        <>
            <BlogHead />
        </>
    )
}

export default Productblog
