import React from "react";
import "../Styles/Styles.css";
import check from "../../Assets/right.png";

const AboutDetail = () => {
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col w-full max-w-[1100px] 2xl:max-w-[1200px] py-[20px] px-[15px] ml-[auto] mr-[auto]">
          <div className="flex lg:flex-row flex-col items-center justify-between  mt-[30px]">
            <div className="flex flex-col w-full lg:w-[48.5%]">
              <div className="text-[#1F4266] text-[28px] lg:text-[32px] leading-[35px] lg:leading-[48px] font-bold fFamily">
                Empowering Businesses Worldwide
              </div>
              <div className="text-[#434343] text-[16px} lg:text-[20px] leading-[20px] lg:leading-[32px] fFamily w-full  lg:max-w-[595px]">
                At Desimo Group AB, we believe in the power of innovation and
                tradition. Our extensive experience in the wholesale market
                enables us to select and offer products that not only meet
                current market trends but also include essential staple items
                that are always in demand. Our portfolio features a diverse
                range of brands known for their quality and reliability,
                ensuring that you always find exactly what you need.
              </div>
            </div>
            <img
              src={require("../../Assets/user1.png")}
              className="w-[300px] h-[300px] mt-[20px] lg:mt-0 lg:w-[48.5%] lg:h-auto"
              alt=""
            />
          </div>
          <div className="flex lg:flex-row flex-col-reverse items-center justify-between mt-[30px]">
            <img
              src={require("../../Assets/user2.png")}
              className="w-[300px] h-[300px] mt-[20px] lg:mt-0 lg:w-[48.5%] lg:h-auto"
              alt=""
            />
            <div className="flex flex-col w-full lg:w-[48.5%]">
              <div className="text-[#1F4266] text-[28px] lg:text-[32px] leading-[35px] lg:leading-[48px] font-bold fFamily">
                Empowering Businesses Worldwide
              </div>
              <div className="text-[#434343] text-[16px} lg:text-[20px] leading-[20px] lg:leading-[32px] fFamily w-full  lg:max-w-[595px]">
                We understand that the needs of our clients are as diverse as
                the products we offer. That's why we are dedicated to
                maintaining a close relationship with our customers, listening
                to their feedback, and adapting our services to better meet
                their evolving needs. Our client-centric approach helps us to
                provide personalized service and support, ensuring that every
                interaction with us adds value to your business.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full mb-[20px] md:mb-[50px] mt-[40px]">
        <div className="flex flex-col w-full max-w-[964px] py-[20px] px-[15px] ml-[auto] mr-[auto]">
          <div className="text-[#1F4266] text-[26px] md:text-[32px] leading-[37px] text-center font-bold">
            Why Choose Us?
          </div>
          <div className="flex  md:flex-row flex-col flex-wrap gap-[10px] sm:gap-[20px]">
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                High Quality, Affordable prices
              </div>
            </div>
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                Secure Payment
              </div>
            </div>
          </div>
          <div className="flex  md:flex-row flex-col flex-wrap  gap-[10px] sm:gap-[20px]">
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                24/7 Customer Support
              </div>
            </div>
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                Complete Customization
              </div>
            </div>
          </div>
          <div className="flex  md:flex-row flex-col flex-wrap  gap-[10px] sm:gap-[20px]">
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                Easy To Use Design Tools
              </div>
            </div>
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                Hire a Designer
              </div>
            </div>
          </div>
          <div className="flex  md:flex-row flex-col flex-wrap  gap-[10px] sm:gap-[20px]">
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                One Stop Shop
              </div>
            </div>
            <div className="flex w-full md:w-[48%] items-center mt-[12px] gap-[10px]">
              <img src={check} className="w-[40px] md:w-[50px]" alt="" />
              <div className="text-[#434343] text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[25px] lg:leading-[32px] fFamily">
                Priority Shipping Available
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDetail;
