import React, { useState } from 'react';
import { IoIosAddCircle } from "react-icons/io";
import styles from './PermanentLinks.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BASEURL from '../../../Utlis/serverinstance'
import axios from 'axios';
import Swal from 'sweetalert2'

const AddBrands = ({ onDeleteSuccess }) => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [permanentHeadingTitle, setPermanentHeadingTitle] = useState('');
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage2(file)

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleTitleChange = (event) => {
        setPermanentHeadingTitle(event.target.value);
    };

    const handleFormSubmit = async () => {
        try {

            if (!permanentHeadingTitle || !selectedImage2) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please Select both Image and Name!',
                    confirmButtonColor: "#008cba"
                });
                return;
            }


            const formData = new FormData();
            formData.append('PermanentHeadingTitle', permanentHeadingTitle);
            formData.append('PermanentHeadingImage', selectedImage2);
            console.log(formData, "formData")
            const response = await axios.post(
                `${BASEURL}/permanentNews/DailyHeading`
                , formData);
            console.log(response.data);  // Handle the response from the server as needed
            setModalShow(false);
            setPermanentHeadingTitle('');
            onDeleteSuccess();

            Swal.fire({
                icon: 'success',
                text: 'Brand Added',
                confirmButtonColor: "#008cba"
            });
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };
    return (
        <>
            <Button className={styles.addStory} onClick={() => setModalShow(true)}>
                <IoIosAddCircle className='text-[25px] text-white' />
                Add Brand
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Brand
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.inputsArea}>
                        <div className={styles.mainImage}>
                            {selectedImage ? (
                                <img src={selectedImage} className={styles.headerImg} alt="" />
                            ) : (
                                <div></div>
                            )}

                            <div className={styles.optionArea}>
                                <label className={styles.imgText}>
                                    <input type="file" accept="image/*" onChange={handleImageChange} />
                                    Select Image
                                </label>
                            </div>
                        </div>
                        <div className={styles.headings}>Brand Name</div>
                        <input type='text' value={permanentHeadingTitle} onChange={handleTitleChange} className={styles.urlInput} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleFormSubmit} className={styles.cancelBtn}>Save</Button>
                    <Button onClick={() => setModalShow(false)} className={styles.cancelBtn}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddBrands
