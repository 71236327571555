import React, { useEffect, useState } from "react";
import "../Styles/Styles.css";
import NavbarBrandCuts from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BASEURL from "../../Utlis/serverinstance";
import axios from "axios";
import Swal from "sweetalert2";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

const BlogHead = () => {
  const [mainHeading, setMainHeading] = useState([]);
  const location = useLocation();
  const ProductItem = location?.state?.productDisplayed;
  let formattedDate = "";

  if (ProductItem?.createdAt) {
    const createdAtDate = new Date(ProductItem.createdAt);
    formattedDate = createdAtDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASEURL}/dailyNews/DailyHeadings`);
      setMainHeading(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="w-full h-[80px] md:h-[95px] blogHeader">
        <NavbarBrandCuts />
      </div>
      <div className="w-full">
        <div className="flex flex-col w-full max-w-[1050px] px[15px] py-[40px] md:py-[70px] mx-auto">
          <div className="itemDtae">{formattedDate}</div>
          <div className="fFamily text-[30px] md:text-[48px] text-[#1E3533] leading-[32px] md:leading-[48px] mt-[30px] md:mt-[40px] text-center">
            {ProductItem?.DailyHeadingURL}
          </div>
          <div className="text-[14px] text-center text-[#1E3533] leading-[20px] mt-[8px] md:mt-[15px]">
            By Alex Nichita, Admin
          </div>
          {ProductItem?.DailyHeadingImage && (
            <img
              src={`${BASEURL}/img/${ProductItem?.DailyHeadingImage}`}
              className=" mx-auto mt-[40px] h-auto w-[300px] md:w-[400px]"
              alt=""
            />
          )}
          <div className="fFamily mt-[30px] text-center leading-[20px] md:leading-[32px] text-[16px]  md:text-[20px]">
            {ProductItem?.DailyHeadingTitle}
          </div>
          <div className="fFamily mt-[20px] text-center leading-[20px] md:leading-[32px] text-[16px]  md:text-[20px]">
            In order to place an order, please get in touch with our{" "}
            <span className="font-[600]">sales team</span> .
          </div>
        </div>
      </div>
      <div className="w-full pt-[50px] pb-[40px] relative">
        <img
          src={require("../../Assets/productsHome.png")}
          className="absolute top-0 z-1 h-full w-full"
          alt=""
        />
        <div className="flex flex-col  ml-auto mr-auto w-full max-w-[1100px] 2xl:max-w-[1200px] relative z-2 px-[15px]">
          <div className="News & Updates fFamily text-center text-[26px] md:text-[32px] text-white font-[600]">
            News & Updates
          </div>
          <div className="flex md:flex-row flex-col flex-wrap  md:justify-between w-full">
            {mainHeading?.map((item) => (
              <div className="flex flex-col mt-[30px] w-full max-w-[400px] md:max-w-none md:w-[48.5%] ">
                {item?.DailyHeadingImage && (
                  <img
                    src={`${BASEURL}/img/${item?.DailyHeadingImage}`}
                    className="w-full max-h-[350px]  sm:h-[350px] h-[250px]"
                    alt=""
                  />
                )}
                <div className="flex w-full gap[20px] mt-[8px] justify-between items-center">
                  <div className="flex flex-col w-full max-w-[390px]">
                    <div className="txt-[20px] md:text-[24px] text-white leading-[25x] md:leading-[38px] font-[600]">
                      {item?.DailyHeadingURL}
                    </div>
                    <div className="text-[16px] md:text-[19px] text-white leading-[20px] md:leading-[28px]">
                      {item?.DailyHeadingTitle}
                    </div>
                  </div>
                  <Link to={`/productblog`} state={{ productDisplayed: item }}>
                    <FaArrowRightLong className="text-[20px] md:text-[24px] text-white" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogHead;
