import React, { useEffect, useState } from 'react'
import AddBrands from './AddBrands'
import NavbarBrandCuts from '../../Navbar/Navbar'
import { useNavigate } from 'react-router-dom';
import '../../Styles/Styles.css'
import BASEURL from '../../../Utlis/serverinstance'
import axios from 'axios';
import Swal from 'sweetalert2'
import DeleteBrand from './DeleteBrand';

const AdminHomeBrands = () => {
    const navigate = useNavigate();
    const [mainHeading, setMainHeading] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${BASEURL}/permanentNews/DailyHeadings`
            );
            setMainHeading(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleDeleteSuccess = (() => {
        fetchData();
    })


    const RemoveId = (() => {
        localStorage.removeItem('adminId')
        navigate('/');
    })



    return (
        <>
            <div className='w-full mb-[60px] h-[125px] md:h-[150px] blogHeader'>
                <NavbarBrandCuts />
                <div className='w-full flex justify-end pr-[2%] md:pt-[85px]'>
                    <button className='bg-[#e50000] text-white w-[70px] h-[35px] rounded-[8px] border-none' onClick={RemoveId} >Logout</button>
                </div>
            </div>
            <div className='w-full py-[20px] md:py-[50px]  relative'>
                <div className='flex flex-col  ml-auto mr-auto w-full max-w-[1100px] 2xl:max-w-[1200px] relative z-2 px-[15px]'>
                    <div className='flex justify-end w-full'><AddBrands onDeleteSuccess={handleDeleteSuccess} /></div>
                    <div className='News & Updates fFamily text-[26px] md:text-[32px]  font-[600]'>All Brands</div>
                    <div className='flex flex-wrap gap-[30px] mt-[20px]'>
                        {mainHeading?.map((item) => (
                            <div className='flex flex-col mt-[10px] md:mt-[15px]'>
                                <div className='flex w-full p-[10px] justify-end'><DeleteBrand ItemsId={item._id} onDeleteSuccess={handleDeleteSuccess} /></div>
                                <div className='flex items-center gap-[10px] mt-[15px]'>
                                    <img src={`${BASEURL}/img/${item?.PermanentHeadingImage}`} className='w-[50px] md:w-[100px]  h-[30px] md:h-[50px]' alt="" />
                                    <div className=' text-[20px] md:text-[32px] font-[600] fFamily text-black'>{item?.PermanentHeadingTitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminHomeBrands
