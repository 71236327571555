import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Route, Routes, Link } from "react-router-dom";
import "../Styles/Styles.css";
import { useLocation } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import BASEURL from "../../Utlis/serverinstance";
import axios from "axios";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

const routeClassMap = {
  "/": "navArea",
  "/brands": "navArea1",
  "/contactus": "navArea2",
  "/aboutus": "navArea3",
  "/adminhome": "navArea",
  "/productblog": "navArea",
};

const NavbarBrandCuts = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const [ignoreClickOutside, setIgnoreClickOutside] = useState(false);
  const [mainHeading, setMainHeading] = useState([]);
  const className = routeClassMap[currentRoute] || "";

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
    setIgnoreClickOutside(true);
  };

  console.log(isTooltipVisible, "isTooltipVisible");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/permanentNews/DailyHeadings`
      );
      setMainHeading(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOutside = (event) => {
    if (
      !ignoreClickOutside &&
      isTooltipVisible &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target)
    ) {
      setTooltipVisible(false);
    }
    setIgnoreClickOutside(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isTooltipVisible, ignoreClickOutside]);

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary navSticky"
        >
          <Container fluid className="fluidSection">
            <Navbar.Brand href="#" className="IconLoga">
              {/* <img src={require("../../Assets/Icon logga.jpg")} alt="" /> */}
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="togl"
            >
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="offCan"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {/* <img
                    className="IconLoga"
                    src={require("../../Assets/Icon logga.jpg")}
                    alt=""
                  /> */}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className={className}>
                  <Link
                    to="/"
                    className={
                      location.pathname === "/" ? "activeLink" : "nonActiveLink"
                    }
                  >
                    <div className="hoverdLink">Home</div>
                  </Link>

                  {/* <div className='flex relative toolParent'>
                                        <div onClick={toggleTooltip} className={location.pathname === "/brands" ? 'activeLink toolparent  ' : 'nonActiveLink toolparent'}>

                                            <div className='hoverdLink'>Brands {isTooltipVisible ? <MdOutlineKeyboardArrowUp className='text-white' /> : <MdOutlineKeyboardArrowDown className='text-white' />}</div>
                                        </div>
                                        {isTooltipVisible && (
                                            <div ref={tooltipRef} className="custom-tooltip  toolchild">
                                                {mainHeading?.map((item) => (
                                                    <Link onClick={toggleTooltip} to='/brands' state={{ SetBrand: item }} className='flex items-center w-[33%] mb-[15px] gap-[10px]'>
                                                        <img src={`${BASEURL}/img/${item?.PermanentHeadingImage}`} className='w-[40px]' alt="" />
                                                        <div className='text-[14px] text-[#111827] font-[500] '>{item?.PermanentHeadingTitle}</div>
                                                    </Link>
                                                ))}
                                            </div>
                                        )}
                                    </div> */}

                  <Link
                    to="/contactus"
                    className={
                      location.pathname === "/contactus"
                        ? "activeLink"
                        : "nonActiveLink"
                    }
                  >
                    <div className="hoverdLink">Contact us</div>
                  </Link>
                  {/* <Link
                    to="/aboutus"
                    className={
                      location.pathname === "/aboutus"
                        ? "activeLink"
                        : "nonActiveLink"
                    }
                  >
                    <div className="hoverdLink">About us</div>
                  </Link> */}
                  <a
                    href="https://desimorewards.com/my-account/
"
                    target="_blank"
                    className="activeLinkGet"
                  >
                    <div className="hoverdLink">Get started</div>
                  </a>
                  <a
                    className="persons"
                    href="https://desimorewards.com/my-account/
"
                    target="_blank"
                  >
                    <div className="hoverdLink1">
                      <svg
                        viewBox="0 0 32 26"
                        width="32"
                        height="26"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <defs>
                          <clipPath id="__lottie_element_23">
                            <rect width="32" height="26" x="0" y="0"></rect>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#__lottie_element_23)">
                          <g
                            id="nav--icon--profil"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity="1"
                          >
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,10.857000350952148,12.25)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(255,255,255)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d=" M9.857000350952148,11.791000366210938 C9.857000350952148,11.019000053405762 9.857000350952148,6.107999801635742 9.857000350952148,6.107999801635742 C9.857000350952148,5.464000225067139 5.914000034332275,3.5369999408721924 2.628000020980835,2.25 C2.628000020980835,2.25 2.628000020980835,-0.32100000977516174 2.628000020980835,-0.32100000977516174 C2.628000020980835,-0.32100000977516174 3.943000078201294,-0.7710000276565552 3.943000078201294,-3.5350000858306885 C4.85699987411499,-3.5350000858306885 5.257999897003174,-6.10699987411499 3.9860000610351562,-6.10699987411499 C3.9860000610351562,-6.379000186920166 4.991000175476074,-7.789000034332275 4.599999904632568,-9.321000099182129 C3.941999912261963,-11.892999649047852 -2.628999948501587,-11.892999649047852 -3.2860000133514404,-9.321000099182129 C-6.052999973297119,-9.875 -3.943000078201294,-6.4770002365112305 -3.943000078201294,-6.10699987411499 C-5.256999969482422,-6.10699987411499 -4.857999801635742,-3.5350000858306885 -3.943000078201294,-3.5350000858306885 C-3.943000078201294,-0.7710000276565552 -2.628000020980835,-0.32100000977516174 -2.628000020980835,-0.32100000977516174 C-2.628000020980835,-0.32100000977516174 -2.628000020980835,2.25 -2.628000020980835,2.25 C-5.914000034332275,3.5369999408721924 -9.857000350952148,5.464000225067139 -9.857000350952148,6.107999801635742 C-9.857000350952148,6.107999801635742 -9.857000350952148,11.892999649047852 -9.857000350952148,11.892999649047852 C-9.857000350952148,11.892999649047852 -0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 C-0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 C-0.10700000077486038,11.892999649047852 9.857000350952148,11.892999649047852 9.857000350952148,11.892999649047852 C9.857000350952148,11.892999649047852 9.857000350952148,11.857000350952148 9.857000350952148,11.791000366210938"
                              ></path>
                            </g>
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,24.55299949645996,14.041000366210938)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(255,255,255)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d=" M-0.8759999871253967,10.10200023651123 C-0.8759999871253967,10.10200023651123 6.447000026702881,10.10200023651123 6.447000026702881,10.10200023651123 C6.447000026702881,10.10200023651123 6.447000026702881,4.236000061035156 6.447000026702881,4.236000061035156 C6.447000026702881,3.5840001106262207 3.11899995803833,2.671999931335449 0.4560000002384186,1.6299999952316284 C0.4560000002384186,1.6299999952316284 0.4560000002384186,-0.32600000500679016 0.4560000002384186,-0.32600000500679016 C0.4560000002384186,-0.32600000500679016 1.7869999408721924,-0.6899999976158142 1.7869999408721924,-2.931999921798706 C2.5290000438690186,-2.931999921798706 2.816999912261963,-5.539999961853027 1.7869999408721924,-5.539999961853027 C1.7869999408721924,-5.760000228881836 2.865999937057495,-6.929999828338623 2.453000068664551,-8.145999908447266 C1.7869999408721924,-10.10099983215332 -3.5380001068115234,-10.10099983215332 -4.203999996185303,-8.145999908447266 C-6.447000026702881,-8.595000267028809 -4.869999885559082,-5.839000225067139 -4.869999885559082,-5.539999961853027 C-5.934999942779541,-5.539999961853027 -5.611000061035156,-2.931999921798706 -4.869999885559082,-2.931999921798706 C-4.869999885559082,-0.6899999976158142 -3.5380001068115234,-0.32600000500679016 -3.5380001068115234,-0.32600000500679016 C-3.5380001068115234,-0.32600000500679016 -3.5380001068115234,0.9769999980926514 -3.5380001068115234,0.9769999980926514"
                              ></path>
                            </g>
                          </g>
                          <g
                            id="nav--icon--profil"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity="1"
                          >
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,10.857000350952148,12.25)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(255,255,255)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d=" M9.857000350952148,11.791000366210938 C9.857000350952148,11.019000053405762 9.857000350952148,6.107999801635742 9.857000350952148,6.107999801635742 C9.857000350952148,5.464000225067139 5.914000034332275,3.5369999408721924 2.628000020980835,2.25 C2.628000020980835,2.25 2.628000020980835,-0.32100000977516174 2.628000020980835,-0.32100000977516174 C2.628000020980835,-0.32100000977516174 3.943000078201294,-0.7710000276565552 3.943000078201294,-3.5350000858306885 C4.85699987411499,-3.5350000858306885 5.257999897003174,-6.10699987411499 3.9860000610351562,-6.10699987411499 C3.9860000610351562,-6.379000186920166 4.991000175476074,-7.789000034332275 4.599999904632568,-9.321000099182129 C3.941999912261963,-11.892999649047852 -2.628999948501587,-11.892999649047852 -3.2860000133514404,-9.321000099182129 C-6.052999973297119,-9.875 -3.943000078201294,-6.4770002365112305 -3.943000078201294,-6.10699987411499 C-5.256999969482422,-6.10699987411499 -4.857999801635742,-3.5350000858306885 -3.943000078201294,-3.5350000858306885 C-3.943000078201294,-0.7710000276565552 -2.628000020980835,-0.32100000977516174 -2.628000020980835,-0.32100000977516174 C-2.628000020980835,-0.32100000977516174 -2.628000020980835,2.25 -2.628000020980835,2.25 C-5.914000034332275,3.5369999408721924 -9.857000350952148,5.464000225067139 -9.857000350952148,6.107999801635742 C-9.857000350952148,6.107999801635742 -9.857000350952148,11.892999649047852 -9.857000350952148,11.892999649047852 C-9.857000350952148,11.892999649047852 -0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 C-0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 -0.10700000077486038,11.892999649047852 C-0.10700000077486038,11.892999649047852 9.857000350952148,11.892999649047852 9.857000350952148,11.892999649047852 C9.857000350952148,11.892999649047852 9.857000350952148,11.857000350952148 9.857000350952148,11.791000366210938"
                              ></path>
                            </g>
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,24.55299949645996,14.041000366210938)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(255,255,255)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d=" M-0.8759999871253967,10.10200023651123 C-0.8759999871253967,10.10200023651123 6.447000026702881,10.10200023651123 6.447000026702881,10.10200023651123 C6.447000026702881,10.10200023651123 6.447000026702881,4.236000061035156 6.447000026702881,4.236000061035156 C6.447000026702881,3.5840001106262207 3.11899995803833,2.671999931335449 0.4560000002384186,1.6299999952316284 C0.4560000002384186,1.6299999952316284 0.4560000002384186,-0.32600000500679016 0.4560000002384186,-0.32600000500679016 C0.4560000002384186,-0.32600000500679016 1.7869999408721924,-0.6899999976158142 1.7869999408721924,-2.931999921798706 C2.5290000438690186,-2.931999921798706 2.816999912261963,-5.539999961853027 1.7869999408721924,-5.539999961853027 C1.7869999408721924,-5.760000228881836 2.865999937057495,-6.929999828338623 2.453000068664551,-8.145999908447266 C1.7869999408721924,-10.10099983215332 -3.5380001068115234,-10.10099983215332 -4.203999996185303,-8.145999908447266 C-6.447000026702881,-8.595000267028809 -4.869999885559082,-5.839000225067139 -4.869999885559082,-5.539999961853027 C-5.934999942779541,-5.539999961853027 -5.611000061035156,-2.931999921798706 -4.869999885559082,-2.931999921798706 C-4.869999885559082,-0.6899999976158142 -3.5380001068115234,-0.32600000500679016 -3.5380001068115234,-0.32600000500679016 C-3.5380001068115234,-0.32600000500679016 -3.5380001068115234,0.9769999980926514 -3.5380001068115234,0.9769999980926514"
                              ></path>
                            </g>
                          </g>
                          <g
                            id="nav--icon--profil--hover"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity="1"
                          >
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,10.857000350952148,12.25)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(38,191,115)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d="M0 0"
                              ></path>
                            </g>
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,24.55299949645996,14.041000366210938)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(38,191,115)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d="M0 0"
                              ></path>
                            </g>
                          </g>
                          <g
                            id="nav--icon--profil--hover"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity="1"
                          >
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,10.857000350952148,12.25)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(38,191,115)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d="M0 0"
                              ></path>
                            </g>
                            <g
                              opacity="1"
                              transform="matrix(1,0,0,1,24.55299949645996,14.041000366210938)"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill-opacity="0"
                                stroke="rgb(38,191,115)"
                                stroke-opacity="1"
                                stroke-width="2"
                                d="M0 0"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </a>
                  <div className=" lowerBrands nonebb flex-wrap w-full px-[10px] gap-[10px] py-[20px]">
                    {mainHeading?.map((item) => (
                      <Link
                        onClick={toggleTooltip}
                        to="/brands"
                        state={{ SetBrand: item }}
                        className="flex nonebb items-center w-[45%]  mb-[15px] gap-[10px]"
                      >
                        <img
                          src={`${BASEURL}/img/${item?.PermanentHeadingImage}`}
                          className=" w-[40px]"
                          alt=""
                        />
                        <div className="text-[14px] text-white font-[500] ">
                          {item?.PermanentHeadingTitle}
                        </div>
                      </Link>
                    ))}
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default NavbarBrandCuts;
