import React, { useState } from 'react';
import { MdDeleteForever } from "react-icons/md";
import styles from './AdminCenter.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BASEURL from '../../../Utlis/serverinstance'
import axios from 'axios';
import Swal from 'sweetalert2'

const DeleteNews = ({ ItemsId, onDeleteSuccess }) => {
    const deleteItemId = ItemsId
    const [modalShow, setModalShow] = useState(false);


    function deleteQuestion() {
        // Replace 'your_backend_endpoint' with the actual URL for your delete question API.
        axios.delete(`${BASEURL}/dailyNews/DailyHeadingdelete/${deleteItemId}`)
            .then(response => {
                Swal.fire('Success', 'News deleted succesfully', 'success');
                // props.onHide();
                onDeleteSuccess();
                setModalShow(false)
            })
            .catch(error => {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message || 'Something went wrong!',
                });
            });
    }

    return (
        <>
            <MdDeleteForever className={styles.editIcon1} onClick={() => setModalShow(true)} />


            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete news
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to delete this news?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={deleteQuestion} className={styles.addStory}>Delete</Button>
                    <Button onClick={() => setModalShow(false)} className={styles.addStory}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default DeleteNews
