import React from "react";
import "../Styles/Styles.css";
import NavbarBrandCuts from "../Navbar/Navbar";
// import del1 from '../../Assets/del1.svg'
// import del2 from '../../Assets/del2.svg'
// import del3 from '../../Assets/del3.svg'

const AboutHead = () => {
  return (
    <>
      <div className="aboutbrand relative">
        <img
          src={require("../../Assets/ABOUThEAD.png")}
          alt=""
          className="w-full h-full relative z-1"
        />
        <div className="absolute z-2 top-0 w-full h-full">
          <NavbarBrandCuts />
          <div className="flex flex-col w-[full] items-center px-[15px] mt-[60px] md:mt-[100px]">
            <div className="fFamily text-white text-[32px] md:text-[41px] font-[700] max-w-[670px] text-center leading-[40px] md:leading-[52px]">
              Elevate Your Brand Visibility with Custom Signs & Displays
            </div>
            <div className="fFamily text-white leading-[20px] md:leading-[32px] text-[16px] md:text-[20px] font-[400] text-center ">
              Best of Signs enables growing business in need of signage for
              in-store <br /> and outdoor marketing to get what they need
              quickly, easily, and <br /> efficiently Our wide range of products
              and integrated tech solutions help <br /> businesses get premium
              quality products at competitive prices.
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex py-[40px] sm:py-[60px]">
        <div className="flex flex-wrap sm:flex-row flex-col justify-between items-center w-full ml-auto mr-auto max-w-[1200px] px-[4%]">
          <div className="flex mt-[15px] sm:mt-0 flex-col items-center">
            {/* <img src={del1} className='h-[76px]' alt="" />
            <div className="text-[#1F4266] text-[32px] my-[10px] font-bold fFamily">
              1000+
            </div>
            <div className="text-[#434343] text-[21px] text-center leading-[29px] fFamily">
              Orders <br /> Manufactured Daily
            </div>
          </div>
          <div className="flex mt-[15px] sm:mt-0  flex-col items-center">
            {/* <img src={del2} className='h-[76px]' alt="" /> 
            <div className="text-[#1F4266] text-[32px] my-[10px] font-bold fFamily">
              10,000+
            </div>
            <div className="text-[#434343] text-[21px] leading-[29px] text-center fFamily">
              Custom
              <br />
              Products Range
            </div>
          </div>
          <div className="flex mt-[15px] sm:mt-0  flex-col items-center">
            {/* <img src={del3} className='h-[76px]' alt="" /> 
            <div className="text-[#1F4266] text-[32px] my-[10px] font-bold fFamily">
              1000+
            </div>
            <div className="text-[#434343] text-[21px] leading-[29px] text-center fFamily">
              Employees
              <br />
              Globally
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutHead;
